import { ComponentProps } from 'react';

import { RouterButton } from 'components/Button/RouterButton';

type Props = ComponentProps<typeof RouterButton> & { reverse?: boolean };

export function SecondaryButtonRouterLink({ reverse, ...props }: Props) {
  return (
    <RouterButton
      variant={reverse ? 'secondary-white' : 'secondary'}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}
