import interpolateComponents from '@automattic/interpolate-components';
import { useEffect, useState } from 'react';

import { Box } from 'components/Box';
import {
  SecondaryButtonRouterLink,
  TrackedSecondaryButtonLink,
} from 'components/Button';
import { LoadingSection } from 'components/LoadingSection/LoadingSection';
import { ShareURLButton } from 'components/ShareURLButton';
import { H2 } from 'components/Text/H2';
import { RichTextHeadline } from 'components/Text/RichTextHeadline';
import { Underline } from 'components/Text/Underline';
import { CmsApiRecentSalariesBlockDataWithAnalytics } from 'modules/cms/api/types/blocks/CmsApiRecentSalariesBlock';
import { salariesFetchRecentSubmissions } from 'modules/salaries/api/salariesFetchRecentSubmissions';
import { salarySurveyRoute } from 'modules/salaries/routing/salaries.routes';
import { SalariesRecentSubmission } from 'modules/salaries/types/SalariesRecentSubmission';
import { colors } from 'theme/theme';

import {
  RecentSubmissionsContainer,
  RecentSubmissionsHeading,
  RecentSubmissionsWrapper,
  SurveyButtonContainer,
  TableBody,
  TableBodyContainer,
  TableCell,
  TableContainer,
  TableHeading,
  aCycle,
} from './RecentSubmissions.styled';

function formatSalary(salary: number): string {
  return salary.toLocaleString(CURRENT_LOCALE, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
}

function useSalaryRecentSubmissions() {
  const [recentSubmissions, setRecentSubmissions] =
    useState<SalariesRecentSubmission[]>();

  useEffect(() => {
    if (!recentSubmissions) {
      salariesFetchRecentSubmissions().then(
        (fetchedRecentSubmissions: SalariesRecentSubmission[]) =>
          setRecentSubmissions(fetchedRecentSubmissions),
      );
    }
  }, [recentSubmissions]);

  return recentSubmissions;
}

type RecentSubmissionsProps = {
  alreadySubmitted: boolean;
  surveyLoading: boolean;
  cmsData?: CmsApiRecentSalariesBlockDataWithAnalytics;
};

export function RecentSubmissions({
  alreadySubmitted,
  surveyLoading,
  cmsData,
}: RecentSubmissionsProps) {
  const recentSubmissions = useSalaryRecentSubmissions();

  const tableHeading = interpolateComponents({
    mixedString: getText('{{s}}Recent Salary Submissions{{/s}}'),
    components: {
      s: <Underline />,
    },
  });

  const shareUrl = {
    en: salarySurveyRoute.fullPath,
    es: salarySurveyRoute.fullPath,
    pt: salarySurveyRoute.fullPath,
  };

  const messageOverride = getText(
    'I just took the Idealist Salary Survey! Find out what you could be earning at a nonprofit in just a few quick steps.',
  );
  const emailMessageOverride = getText(
    'I just took the Idealist Salary Survey! Find out what you could be earning at a nonprofit in just a few quick steps. Start here:',
  );
  const emailSubjectOverride = getText('Check this out: Idealist Salaries');

  const data = recentSubmissions
    ? recentSubmissions
        .concat(recentSubmissions.slice(0, 4))
        .map((submission) => [
          submission.title,
          formatSalary(submission.salary),
          submission.location,
        ])
    : [];

  return (
    <RecentSubmissionsContainer>
      <RecentSubmissionsWrapper>
        <RecentSubmissionsHeading>
          {cmsData ? (
            <RichTextHeadline>{cmsData.headline}</RichTextHeadline>
          ) : (
            <H2>
              {getText('Help us research pay equity in the nonprofit sector')}
            </H2>
          )}

          {cmsData && (
            <TrackedSecondaryButtonLink
              href={cmsData.buttonUrl}
              $reverse
              eventName="CMS Block"
              eventProperties={{
                block_position: cmsData.blockIndex,
                block_type: 'Recent Salaries',
                cta_type: 'Button',
                button_text: cmsData.buttonText,
                page_updated: cmsData.pageUpdated,
              }}
            >
              {cmsData.buttonText}
            </TrackedSecondaryButtonLink>
          )}

          {!cmsData && !surveyLoading && (
            <SurveyButtonContainer>
              {alreadySubmitted ? (
                <ShareURLButton
                  buttonText="Share Our Survey"
                  shareUrl={shareUrl}
                  variant="secondary-white"
                  messageOverride={messageOverride}
                  emailMessageOverride={emailMessageOverride}
                  emailSubjectOverride={emailSubjectOverride}
                />
              ) : (
                <SecondaryButtonRouterLink
                  to={salarySurveyRoute.fullPath}
                  reverse
                >
                  {getText('Take Our Survey')}
                </SecondaryButtonRouterLink>
              )}
            </SurveyButtonContainer>
          )}
        </RecentSubmissionsHeading>

        <TableContainer>
          <TableHeading>{tableHeading}</TableHeading>
          {!recentSubmissions ? (
            <LoadingSection />
          ) : (
            <>
              <Box
                display="flex"
                fontWeight={600}
                backgroundColor={colors.backgroundGrey}
              >
                <TableCell
                  style={{ width: '65%', color: colors.lightContentGrey }}
                >
                  {getText('Position')}
                </TableCell>
                <TableCell
                  style={{ width: '15%', color: colors.lightContentGrey }}
                >
                  {getText('Salary')}
                </TableCell>
                <TableCell
                  style={{ width: '20%', color: colors.lightContentGrey }}
                >
                  {getText('Location')}
                </TableCell>
              </Box>

              <TableBodyContainer>
                <TableBody
                  $animationName={aCycle(data)}
                  $itemCount={data.length}
                >
                  {data.map((item) => (
                    <Box display="flex">
                      <TableCell style={{ width: '65%' }}>{item[0]}</TableCell>
                      <TableCell style={{ width: '15%' }}>{item[1]}</TableCell>
                      <TableCell style={{ width: '20%' }}>{item[2]}</TableCell>
                    </Box>
                  ))}
                </TableBody>
              </TableBodyContainer>
            </>
          )}
        </TableContainer>
      </RecentSubmissionsWrapper>
    </RecentSubmissionsContainer>
  );
}
