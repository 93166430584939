import { ComponentProps } from 'react';

import { TrackedLink } from 'components/Link';

import { LegacySecondaryButton as SecondaryButton } from './LegacySecondaryButton';

export function TrackedSecondaryButtonLink(
  props: ComponentProps<typeof SecondaryButton> &
    ComponentProps<typeof TrackedLink>,
) {
  return (
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SecondaryButton component={TrackedLink} type={null} {...props} />
  );
}
