import { ComponentProps } from 'react';
import styled from 'styled-components';

import { Link } from 'components/RouterLink/RouterLink';

import { Button } from './Button';

type Props = ComponentProps<typeof Button> & ComponentProps<typeof Link>;

const StyledLink = styled(Link)`
  text-decoration: none;

  &.full-width {
    width: 100%;
  }
`;

export function RouterButton({
  // common props from Button:
  'aria-label': ariaLabel,
  'data-qa-id': dataQaId,
  'data-qa-submitting': dataQaSubmitting,
  children,
  className,
  disabled,
  fullWidth,
  icon,
  iconPosition,
  id,
  noPadding,
  onBlur,
  onClick,
  onFocus,
  role,
  size,
  tabIndex,
  title,
  type,
  variant,
  // props unique to RouterButton:
  to,
  state,
}: Props) {
  return (
    <StyledLink
      to={to}
      state={state}
      className={fullWidth ? 'full-width' : ''}
      // common props:
      aria-label={ariaLabel}
      data-qa-submitting={dataQaSubmitting}
      id={id}
      onClick={onClick}
    >
      <Button
        className={className}
        data-qa-id={dataQaId}
        disabled={disabled}
        fullWidth={fullWidth}
        icon={icon}
        iconPosition={iconPosition}
        noPadding={noPadding}
        onBlur={onBlur}
        onFocus={onFocus}
        role={role}
        size={size}
        tabIndex={tabIndex}
        title={title}
        type={type}
        variant={variant}
      >
        {children}
      </Button>
    </StyledLink>
  );
}
