import styled from 'styled-components';

import { colors } from 'theme/theme';

import { ButtonWithStyles } from './ButtonWithStyles';

export const LegacySecondaryButton = styled(ButtonWithStyles)<{
  $reverse?: boolean;
  $width?: number;
}>`
  color: ${(props) => (props.$reverse ? colors.white : colors.brandBlue)};
  ${(props) => props.$width && `width: ${props.$width}px;`};

  border: 1px solid
    ${(props) => (props.$reverse ? colors.white : colors.brandBlue)};

  &:not([disabled]):hover {
    background-color: ${colors.softBlue};
    text-decoration: none;
  }
`;
